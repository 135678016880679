export const steps = [
  {
    title: 'product',
    content: 'First-content',
  },
  {
    title: 'product.property',
    content: 'Second-content',
  },
  {
    title: 'finish',
    content: 'Third-content',
  },
];
