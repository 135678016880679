export const PROJECT_NAME = 'Deals4me';
export const BASE_URL = 'https://backend.deals4me.cm';
export const WEBSITE_URL = 'https://deals4me.cm';
export const api_url = BASE_URL + '/api/v1/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const export_url = BASE_URL + '/storage/';
export const IMG_URL = BASE_URL + '/storage/images/';
export const MAP_API_KEY = 'AIzaSyBJ5uQMBFgSr2QFIJvrHAveXovzXJdDWmg';
export const VAPID_KEY =
  'BAooxBVqfZCszCslmM7EJzfkmzA2UrtepgRKRRxxNCXocdBkkNcBUwM-gZnPrY6w1CpgmxFfKgxc9sRiJGpF_4U';
export const STRIPE_PUBLISHABLE_KEY =
  'pk_test_51LaGXyLrMlc0ddAAYc8wxwq8eFj9HbgpvValjxRtg2OZzTJDwFz0ZmATL1TOqAWUFBM3CeHbI5Cny71MzkrAXRxa00XTCJArPr';
export const PAYPAL_KEY =
  'ARKhb_cfiyLAGikamzpXnWEiynGxcGeOkmuxWQ9i1CUztdQy3F_UT4C7vbtR6sfPQ3kxecBi_NL_UEDF';
export const PAYSTACK_KEY = 'pk_test_2ec2bc5ed445385489385b2fce6cddfbfb265914';
export const RAZORPAY_KEY = 'rzp_test_TMPwvQpYGAIMbU';
export const StripeApi = 'https://deals4me.cm/api/create-stripe-session';
export const RazorpayApi = 'https://deals4me.cm/api/create-razorpay-session';

export const API_KEY = 'AIzaSyBo3SnugTuTY0gmA322lvBO594vONh_TEE';
export const AUTH_DOMAIN = 'deals4me.firebaseapp.com';
export const PROJECT_ID = 'deals4me';
export const STORAGE_BUCKET = 'deals4me.appspot.com';
export const MESSAGING_SENDER_ID = '1091088298133';
export const APP_ID = '1:1091088298133:web:591d88de06ab6c6f7fab9c';
export const MEASUREMENT_ID = 'G-5NVVRN77ZD';
export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/svg',
];
