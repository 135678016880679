import React, { useEffect, useState } from 'react';
import { Card, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchRoles } from '../../redux/slices/role';
import { disableRefetch } from '../../redux/slices/menu';
import FilterColumns from '../../components/filter-column';

export default function RoleList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, roles } = useSelector((state) => state.role, shallowEqual);
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const [columns, setColumns] = useState([
    {
      title: t('id'),
      is_show: true,
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('name'),
      is_show: true,
      dataIndex: 'name',
      key: 'name',
      render: (name) => t(name),
    },
  ]);

  useEffect(() => {
    if (activeMenu?.refetch) {
      dispatch(fetchRoles());
      dispatch(disableRefetch(activeMenu));
    }
  }, [activeMenu?.refetch]);

  return (
    <Card
      title={t('roles')}
      extra={<FilterColumns setColumns={setColumns} columns={columns} />}
    >
      <Table
        columns={columns?.filter((items) => items.is_show)}
        dataSource={roles}
        loading={loading}
        pagination={false}
        rowKey={(record) => record.id}
      />
    </Card>
  );
}
